import { RouteConfig } from 'vue-router'
// TODO: @JH this route is not longer used, TrackingVisualizationPresent is in runs
const routes = [{
  path: '/tracking-evaluation/:evaluationId/present',
  name: 'tracking-evaluation.present',
  // component: () => import(/* webpackChunkName: "tracking-evaluation-views" */'../../tracking-evaluation/views/TrackingVisualizationPresent.vue'),
  props: true,
  meta: {
    breadcrumbs: {
      text: 'trackingEvaluation.global.evaluation',
      to: null
    }
  }
}] as RouteConfig[]

export {
  routes
}
